<template>
    <div
        ref="carousel"
        class="w-carousel"
        :style="visibleStyles"
    >
        <div
            ref="pane"
            class="w-carousel__slides-wrapper"
        >
            <div
                ref="slides"
                class="w-carousel__slides"
                :style="containerStyles"
            >
                <div
                    v-for="(slide, index) in slides"
                    :key="index"
                    class="w-carousel__slides-item"
                    data-virtual
                >
                    <slot
                        name="slide"
                        :slide="slide"
                        :index="index"
                        :isFirst="index === 0"
                        :isLast="index === slides.length - 1"
                    />
                </div>
                <slot name="slides" />
            </div>
        </div>
        <transition name="fade">
            <div
                v-if="showButtonPrevious"
                class="w-carousel__navigation w-carousel__navigation--left"
            >
                <div
                    class="w-carousel__navigation-button w-carousel__navigation-button--left"
                    @click="previous"
                >
                    <pointer-icon class="w-carousel__navigation-button-icon w-carousel__navigation-button-icon--prev" />
                </div>
            </div>
        </transition>
        <transition name="fade">
            <div
                v-if="showButtonNext"
                class="w-carousel__navigation w-carousel__navigation--right"
            >
                <div
                    class="w-carousel__navigation-button w-carousel__navigation-button--right"
                    @click="next"
                >
                    <pointer-icon class="w-carousel__navigation-button-icon w-carousel__navigation-button-icon--next" />
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
    import PointerIcon from 'Assets/icons/pointer-right.svg?component'

    export default {
        name: 'BaseCarousel',
        components: {
            PointerIcon
        },
        props: {
            slides: {
                type: Array,
                default: () => []
            },
            speed: {
                type: Number,
                default: 500
            },
            height: {
                type: Number,
                default: null
            },
            showNavigation: {
                type: Boolean,
                default: true
            },
            onSlideClick: {
                type: Function,
                default (item) {
                    this.$emit('on-slide-click', item)
                }
            }
        },
        data () {
            return {
                initialized: false,
                slidesObserver: null,
                animationInProgress: false,
                currentSlideIndex: 0,
                containerHeight: 0,
                containerWidth: 0,
                visibleHeight: 0,
                visibleWidth: 0,
                slidesSizes: []
            }
        },
        computed: {
            visibleSlidesCount () {
                if (!this.visibleWidth || !this.slidesSizes.length) return 0

                let count = 0
                let width = 0

                for (const slide of this.slidesSizes.slice(this.currentSlideIndex)) {
                    if ((width += slide.width) >= this.visibleWidth) return count

                    count += 1
                }

                return count
            },
            visibleStyles () {
                const height = `${this.visibleHeight}px`

                return { height }
            },
            containerStyles () {
                if (!this.initialized || !this.haveHiddenSlides) return undefined

                if (this.isCurrentIndexLast) {
                    const lastSlideSizes = this.slidesSizes.slice(-1)[0]
                    const lastSlideWidth = lastSlideSizes.width
                    const reminder = this.visibleWidth - this.remainingSlidesWidth

                    // Get proportion to calculate transition speed for last slide smoother effect
                    const gap = lastSlideWidth - reminder
                    const gapToSlide = (gap / lastSlideWidth * 100).toFixed()
                    const gapTransitionMs = (this.speed / 100 * gapToSlide).toFixed()

                    return {
                        transitionDuration: `${gapTransitionMs}ms`,
                        left: `-${this.hiddenSlidesWidth - reminder}px`
                    }
                }

                // TODO: fix transition speed from last slide to previous
                return {
                    transitionDuration: `${this.speed}ms`,
                    left: `-${this.hiddenSlidesWidth}px`
                }
            },
            haveHiddenSlides () {
                return this.containerWidth > this.visibleWidth
            },
            hiddenSlidesWidth () {
                if (!this.haveHiddenSlides) return 0

                return this.slidesSizes
                    .slice(0, this.currentSlideIndex)
                    .reduce((acc, el) => acc + el.width, 0)
            },
            remainingSlidesWidth () {
                if (!this.haveHiddenSlides) return 0

                return this.slidesSizes
                    .slice(this.currentSlideIndex)
                    .reduce((acc, el) => acc + el.width, 0)
            },
            isCurrentIndexFirst () {
                return this.currentSlideIndex === 0
            },
            isCurrentIndexLast () {
                return this.remainingSlidesWidth <= this.visibleWidth
            },
            showButtonPrevious () {
                return this.isNavigationVisible
                    && !this.isCurrentIndexFirst
            },
            showButtonNext () {
                return this.isNavigationVisible
                    && !this.isCurrentIndexLast
            },
            isNavigationVisible () {
                return this.showNavigation
                    && this.haveHiddenSlides
            }
        },
        mounted () {
            this.slidesObserver = new MutationObserver(() => this.updateCarouselSizes())
            this.slidesObserver.observe(this.$refs.slides, { subtree: false, childList: true })
            this.initialized = true

            this.updateCarouselSizes()

            window.addEventListener('resize', () => this.updateCarouselSizes())
        },
        beforeDestroy () {
            this.slidesObserver.disconnect()
        },
        methods: {
            previous () {
                this.scrollSlides('left')
            },
            next () {
                this.scrollSlides('right')
            },
            scrollSlides (direction) {
                if (this.animationInProgress) return undefined

                this.animationInProgress = true

                if (direction === 'left' && !this.isCurrentIndexFirst) {
                    this.currentSlideIndex -= 1
                }

                if (direction === 'right' && !this.isCurrentIndexLast) {
                    this.currentSlideIndex += 1
                }

                // TODO: fix timeout for last slide, because it has different animation speed
                setTimeout(() => (this.animationInProgress = false), this.speed)
            },
            updateCarouselSizes () {
                // TODO: desktop only
                // Scroll to the last or stay at the current position if slide is still available
                this.currentSlideIndex = 0

                if (!this.initialized) return undefined

                const { carousel, pane, slides } = this.$refs

                if (!pane || !carousel || !slides) return undefined

                // TODO: mobile only
                // Scroll to the last or stay at the current position if slide is still available
                pane.scrollLeft = 0

                // Get sizes of whole slides container
                this.containerHeight = slides.clientHeight
                this.containerWidth = slides.clientWidth

                // Get sizs of visible area
                this.visibleHeight = this.height || this.containerHeight
                this.visibleWidth = carousel.clientWidth

                const { children } = slides

                // Get slides sizes
                this.slidesSizes = [ ...children ].map(el => ({
                    width: el.clientWidth,
                    height: el.clientHeight
                }))
            }
        }
    }
</script>

<style lang="scss">
    @import 'Assets/scss/_transitions';

    .w-carousel {
        display: flex;
        overflow: hidden;
        position: relative;
        width: 100%;

        &__slides-wrapper {
            flex-grow: 1;
            -webkit-overflow-scrolling: touch;
            -ms-overflow-style: -ms-autohiding-scrollbar;
            overflow-x: scroll;
            position: relative;
            scrollbar-width: none;

            &::-webkit-scrollbar {
                display: none;
            }
        }

        &__slides {
            display: flex;
            left: 0;
            min-height: 100%;
            min-width: 100%;
            position: absolute;
            top: 0;
            transition-property: left;
            transition-timing-function: ease-in-out;
        }

        &__navigation {
            background-color: transparent;
            bottom: 0;
            display: none;
            position: absolute;
            top: 0;
            user-select: none;
            width: 80px;
            z-index: 4;

            @media (min-width: 1024px) {
                display: block;
            }

            &--left {
                background:
                    linear-gradient(
                        90deg,
                        rgba(255, 255, 255, 1) 0%,
                        rgba(255, 255, 255, .9) 20%,
                        rgba(255, 255, 255, .1) 90%,
                        rgba(255, 255, 255, 0) 100%
                    );
                border-left: 20px solid #fff;
                left: -20px;
            }

            &--right {
                background:
                    linear-gradient(
                        90deg,
                        rgba(255, 255, 255, 0) 0%,
                        rgba(255, 255, 255, .1) 10%,
                        rgba(255, 255, 255, .9) 80%,
                        rgba(255, 255, 255, 1) 100%
                    );
                right: 0;
            }
        }

        &__navigation-button {
            align-items: center;
            background-color: #fff;
            border-radius: 50%;
            box-shadow: 0 2px 10px rgba(0, 0, 0, .15);
            cursor: pointer;
            display: flex;
            height: 46px;
            justify-content: center;
            margin-top: -23px;
            position: absolute;
            top: 50%;
            transition: transform .15s ease-in-out;
            width: 46px;

            &:hover {
                transform: scale(1.05);
            }

            &:active {
                transform: scale(.98);
            }

            &--left {
                left: 10px;
            }

            &--right {
                right: 10px;
            }
        }

        &__navigation-button-icon {
            fill: #333;
            height: 16px;
            padding-left: 2px;

            &--prev {
                transform: scaleX(-1);
            }
        }
    }
</style>
