<template>
    <div class="footer">
        <!-- <div class="links links--border">
            <a
                v-for="item in mainlinks"
                class="links__item links__item--black"
                :href="item.href"
                :key="item.href"
                v-text="item.text"
            />
        </div> -->
        <div class="links links--padding links--border">
            <p
                v-for="(item, index) in sectionLinks"
                class="section-links"
                :key="index"
            >
                <a
                    v-for="link in item"
                    class="links__item links__item--grey"
                    :href="link.href"
                    :key="link.href"
                    v-text="link.text"
                />
            </p>
        </div>
        <div class="footer__credits">
            <div class="hub">
                <div class="hub__logo">
                    <hub-logo class="hub__svg" />
                </div>
                <div class="hub__text">Участник международного<br/> технологического парка "Астана Хаб"</div>
            </div>
            <div class="social">
                <div
                    style="display: none;"
                    v-html="SocialIcons"
                />
                <a
                    v-for="(item, index) in social"
                    class="social__item"
                    :key="index"
                    :href="item.link"
                    target="_blank"
                >
                    <svg
                        slot="icon"
                        class="social__svg"
                    >
                        <use :xlink:href="`#${item.alias}`" />
                    </svg>
                </a>
            </div>
        </div>
        <div class="credits">
            {{ getDate() }} Workle Pro — официальная интернет-работа
        </div>
    </div>
</template>

<script>
    import SocialIcons from '!!svg-inline-loader!Assets/sprites/social-icons.svg'
    import HubLogo from 'Components/assets/astana-hub.svg?component'

    export default {
        name: 'HomeFooter',
        components: {
            HubLogo
        },
        data () {
            return {
                SocialIcons,
                mainlinks: [
                    {
                        text: 'О нас',
                        href: '/content/workle.pro/'
                    },
                    {
                        text: 'Для кого',
                        href: '/promo/for-whom/'
                    },
                    {
                        text: 'Наши партнеры',
                        href: '/promo/partners/'
                    },
                    {
                        text: 'Возможности',
                        href: '/promo/features/'
                    },
                    {
                        text: 'Блог об удаленной работе',
                        href: '/publications/online/'
                    },
                    {
                        text: 'Контакты',
                        href: '/promo/contacts/'
                    },
                    {
                        text: 'Ответы на вопросы',
                        href: '/help-center/'
                    }
                ],
                sectionLinks: [
                    // [
                    //     {
                    //         text: 'Работа на дому',
                    //         href: '/content/rabota-na-domu/'
                    //     },
                    //     {
                    //         text: 'Работа для мам',
                    //         href: '/content/rabota-dlya-mam-v-dekrete/'
                    //     },
                    //     {
                    //         text: 'Работа для студентов',
                    //         href: '/content/rabota-dlya-studentov-na-domu/'
                    //     },
                    //     {
                    //         text: 'Работа для инвалидов',
                    //         href: '/content/rabota-dlya-invalidov-na-domu/'
                    //     },
                    //     {
                    //         text: 'Специалист по туризму удаленно',
                    //         href: '/content/tourism-specialist/'
                    //     },
                    //     {
                    //         text: 'Специалист по страхованию удаленно',
                    //         href: '/content/insurance-specialist/'
                    //     },
                    //     {
                    //         text: 'Специалист по финансам удаленно',
                    //         href: '/content/finance-specialist/'
                    //     },
                    //     {
                    //         text: 'Пассивный доход в интернете',
                    //         href: '/content/passivniy-dohod-v-internete/'
                    //     },
                    //     {
                    //         text: 'Электронный полис ОСАГО онлайн',
                    //         href: '/content/kupit-e-osago-online/'
                    //     },
                    //     {
                    //         text: 'Полис ОСАГО онлайн',
                    //         href: '/content/kupit-polis-osago-online/'
                    //     },
                    //     {
                    //         text: 'Пакетные туры',
                    //         href: '/content/paketnie-turi/'
                    //     }
                    // ],
                    [
                        {
                            text: 'Условия использования',
                            href: '/resources/doc/offer.htm'
                        },
                        {
                            text: 'Политика конфиденциальности',
                            href: '/resources/doc/personal-data-processing-policy.htm'
                        },
                        // {
                        //     text: 'Умный колл-центр для бизнеса',
                        //     href: '/content/b2b-call-center/?w_starter'
                        // }
                    ],
                    // [
                    //     {
                    //         text: 'Реклама на сайте',
                    //         href: '/content/advertising/'
                    //     },
                    //     {
                    //         text: 'Пресс-центр',
                    //         href: '/press-center/'
                    //     },
                    //     {
                    //         text: 'Сотрудничество',
                    //         href: '/content/partners_new/'
                    //     }
                    // ]
                ],
                social: [
                    {
                        name: 'telegram',

                        link: 'https://t.me/worklekz',

                        alias: 'telegram'
                    },
                    {
                        name: 'instagram',

                        link: 'https://www.instagram.com/worklekz/',
                        
                        alias: 'instagram'
                    },
                    {
                        name: 'facebook',
                        link: 'https://www.facebook.com/profile.php?id=100087169303244',
                        alias: 'facebook'
                    }
                ]
            }
        },
        methods: {
            getDate () {
                const date = new Date()

                return `© 2011-${date.getFullYear()}.`
            }
        }
    }
</script>

<style lang="scss" scoped>
    .footer {
        padding: 24px 20px 6px;
        max-width: 1170px;
        margin: auto;
        font-size: 14px;

        @media (min-width: 480px) {
            padding: 24px 72px 6px 73px;
        }

        @media (min-width: 768px) {
            padding: 24px 20px 6px;
        }

        &__credits {
            padding-top: 24px;
            display: flex;
            flex-direction: column;
            align-items: center;

            @media (min-width: 768px) {
                flex-direction: row;
                justify-content: space-between;
            }

            @media (min-width: 1024px) {
                padding-top: 30px;
            }
        }
    }

    .social {
        display: flex;
        justify-content: space-between;
        padding: 24px 0 30px;
        width: 280px;

        @media (min-width: 768px) {
            padding: 0;
            justify-content: flex-start;
            gap: 20px;
            width: 415px;
        }

        &__item {
            width: 38px;
            height: 38px;
            background: #333;
            border-radius: 50%;
            display: flex;
            padding: 10px;

            &:hover {
                background: #0ca9ef;
            }
        }

        &__svg {
            fill: #fff;
        }
    }

    .credits {
        color: #979797;
        text-align: center;
    }

    .links {
        &--border {
            border-bottom: 1px solid #dadada;
            padding-bottom: 10px;
        }

        &--padding {
            padding-top: 10px;
            padding-bottom: 12px;
        }

        &__item {
            font-size: 16px;
            display: flex;
            align-items: center;
            text-decoration: none;
            display: inline-block;

            &--black {
                padding-right: 20px;
                padding-bottom: 14px;
                font-size: 16px;
                line-height: 100%;
                color: #333;

                &:hover {
                    color: #0ca9ef
                }
            }

            &--grey {
                color: #979797;
                padding-right: 12px;
                padding-bottom: 12px;
                font-size: 14px;

                &:hover {
                    color: #333
                }
            }
        }
    }

    .section-links {
        padding-bottom: 24px;
    }
</style>
