<template>
    <div class="workle-select">
        <div class="select__wrapper">
            <div
                :class="{
                    'select': true,
                    'select--filled': value,
                    'select--error': errors.length && !disabled,
                    'select--disabled': disabled
                }"
            >
                <div
                    :class="{
                        'select__choosen': true,
                        'select__choosen--hidden': searchEnabled && !collapsed,
                        'select__choosen--disabled': disabled,
                        'select__choosen--searchable': searchEnabled
                    }"
                    @mousedown.prevent="onMousedown"
                >
                    <slot name="selection">
                        {{ selected }}
                    </slot>
                </div>
                <div
                    :class="{
                        'select__search': true,
                        'select__search--hidden': !searchEnabled
                    }"
                >
                    <input
                        v-model="search"
                        ref="search"
                        autocomplete="nope"
                        type="text"
                        class="select__search-input"
                        :name="name"
                        @blur="onBlur"
                        @input="onInput"
                        @focus="onFocus"
                        @keydown.enter="onEnter"
                        @keydown.escape="onEscape"
                        @keydown.arrow-up="onArrowKeyUp"
                        @keydown.arrow-down="onArrowKeyDown"
                    >
                </div>
                <div
                    v-if="searchable"
                    class="select__icon-wrapper select__icon-wrapper--search"
                >
                    <search-icon
                        :class="{
                            'select__icon': true,
                            'select__icon--search': true
                        }"
                    />
                </div>
                <div
                    v-else
                    class="select__icon-wrapper select__icon-wrapper--chevron"
                >
                    <pointer-icon
                        :class="{
                            'select__icon': true,
                            'select__icon--chevron': true,
                            'select__icon--chevron-active': !collapsed
                        }"
                    />
                </div>
                <transition name="select">
                    <div
                        v-if="!collapsed"
                        ref="options"
                        class="select__options"
                        :style="optionStyles"
                    >
                        <div
                            v-for="(option, index) in filteredOptions"
                            :key="index"
                            :class="{
                                'select__option': true,
                                'select__option--selected': option.isSelected,
                                'select__option--highlighted': option.isHighlighted
                            }"
                            @mousedown="onSelect(option)"
                        >
                            <slot
                                name="option"
                                :option="option"
                            >
                                <span>{{ option.text }}</span>
                            </slot>
                        </div>
                    </div>
                </transition>
            </div>
            <span
                :class="{
                    'select__text': true,
                    'select__text--active': value || (searchEnabled && !collapsed),
                    'select__text--error': errors.length && !disabled
                }"
                v-html="text"
            />
        </div>
        <div
            v-if="errors.length && !disabled"
            class="input__errors-wrapper"
        >
            <div
                v-for="(error, index) in errors"
                class="input__error"
                :key="index"
                v-html="error"
            />
        </div>
    </div>
</template>

<script>
    import { debounce } from 'Utils/helpers'

    import PointerIcon from 'Assets/icons/pointer-right.svg?component'
    import SearchIcon from 'Assets/icons/search.svg?component'

    export default {
        name: 'WSelect',
        components: {
            PointerIcon,
            SearchIcon
        },
        props: {
            value: [ Object, String ],
            name: {
                type: String,
                required: true
            },
            debounceTime: {
                type: Number,
                default: 0
            },
            maxHeight: Number,
            options: {
                type: Array,
                default: []
            },
            debounce: {
                type: Number,
                default: 0
            },
            disabled: {
                type: Boolean,
                default: false
            },
            editable: {
                type: Boolean,
                default: false
            },
            text: String,
            searchable: false,
            filterable: false,
            errors: {
                type: Array,
                default: () => []
            }
            // TODO: create rewritable onMethods()
        },
        computed: {
            filteredOptions () {
                const { value, search, filterable, options, highlightedIndex } = this
                const filtered = filterable
                    ? options.filter(el => el.text === search)
                    : options

                return filtered.map((el, index) => {
                    const isSelected = el === value || (el && value ? el.id === value.id : false)
                    const isHighlighted = highlightedIndex === index

                    return Object.assign({ isSelected, isHighlighted }, el)
                })
            },
            heightlightedOptionOffsetTop () {
                if (!this.$refs.options || this.highlightedIndex === -1) return 0

                const optionsWrapper = Array.from(this.$refs.options.children)

                return optionsWrapper
                    .slice(0, this.highlightedIndex)
                    .reduce((acc, el) => (acc + this.getElementOuterHeight(el)), 0)
            },
            heightlightedOptionOffsetHeight () {
                if (!this.$refs.options || this.highlightedIndex === -1) return 0

                const optionsWrapper = Array.from(this.$refs.options.children)
                const optionElement = optionsWrapper[this.highlightedIndex]

                return this.getElementOuterHeight(optionElement)
            },
            optionStyles () {
                return {
                    maxHeight: this.maxHeight ? this.maxHeight + 'px' : undefined
                }
            },
            searchEnabled () {
                return this.filterable || this.searchable
            },
            selected () {
                return this.valueIsObject ? this.value.text : this.value
            },
            valueIsObject () {
                return typeof this.value === 'object' && this.value !== null
            }
        },
        data () {
            return {
                $_validation: {},
                collapsed: true,
                highlightedIndex: 0,
                search: ''
            }
        },
        methods: {
            getElementOuterHeight (el) {
                const height = el.offsetHeight
                const style = getComputedStyle(el)

                return height + parseInt(style.marginTop, 10) + parseInt(style.marginBottom, 10)
            },
            onMousedown (e) {
                this.$refs.search.focus()
            },
            onBlur (e) {
                this.onCollapse(e)
            },
            onEnter (e) {
                const index = this.highlightedIndex
                const value = this.filteredOptions[index]

                this.onSelect(value)
                this.$refs.search.blur()
            },
            onEscape (e) {
                this.$refs.search.blur()
            },
            onArrowKeyUp (e) {
                if (this.highlightedIndex === 0) return undefined

                this.highlightedIndex -= 1

                const optionsWrapperScrollTop = this.$refs.options.scrollTop
                const scrollTop = this.heightlightedOptionOffsetTop

                if (scrollTop < optionsWrapperScrollTop) {
                    this.$refs.options.scrollTop -= (optionsWrapperScrollTop - scrollTop)
                }
            },
            onArrowKeyDown (e) {
                if (this.highlightedIndex === this.filteredOptions.length) return undefined

                this.highlightedIndex += 1

                const optionsWrapperHeight = this.$refs.options.offsetHeight
                const optionsWrapperScrollTop = this.$refs.options.scrollTop
                const viewboxOffset = optionsWrapperHeight + optionsWrapperScrollTop
                const scrollTop = this.heightlightedOptionOffsetTop + this.heightlightedOptionOffsetHeight

                if (scrollTop > viewboxOffset) {
                    this.$refs.options.scrollTop += (scrollTop - viewboxOffset)
                }
            },
            onCollapse (e) {
                this.search = ''
                this.collapsed = true
                this.highlightedIndex = 0

                this.$emit('collapse', this.value)
            },
            onFocus (e) {
                if (this.disabled) return undefined

                this.collapsed = false
                this.highlightedIndex = this.filteredOptions.findIndex(el => el.isSelected)

                if (this.selected && this.highlightedIndex !== -1) {
                    this.$nextTick(() => {
                        this.$refs.options.scrollTop = this.heightlightedOptionOffsetTop
                    })
                }

                if (!e.target.isEqualNode(this.$refs.search)) {
                    this.search = this.selected
                    this.$refs.search.focus()
                }

                //this.$emit('focus', e)

                if (!this.editable) return undefined

                this.search = this.selected
            },
            onInput (e) {
                const value = e.target.value

                if (this.debounce > 0) {
                    return this.onDebounce(this, e.target.value)
                }

                return this.onSearch(value)
            },
            onSearch (value) {
                this.highlightedIndex = 0
                this.$emit('on-search', value)
            },
            onSelect (value) {
                this.$emit('input', value)

                if (!this.editable) return undefined

                this.$emit('on-search', value.text)
            },
            onDebounce: debounce((context, val) => {
                context.onSearch(val)
            }, 1000)
        }
    }
</script>

<style lang="scss">
    @import 'Assets/scss/ui/_typography';
    @import 'Assets/scss/ui/_form-elements';

    // Transitions
    .select-enter-active,
    .select-leave-active {
        transition: opacity .3s;
    }

    .select-enter,
    .select-leave-to {
        opacity: 0;
    }

    .workle-select {
        width: 100%;
    }

    .select {
        box-sizing: border-box;
        background-color: #fff;
        font-family: $font-PT-Sans;
        border-radius: $border-radius-input;
        letter-spacing: normal;
        line-height: $height-input-md;
        height: $height-input-md;
        color: $color-default;
        border: $border-input;
        width: 100%;

        &--error {
            border: $border-input-error;
        }

        &--disabled:not(&--filled) + &__text {
            @include label-disabled;
        }

        &__wrapper {
            display: flex;
            line-height: rem(14);
            position: relative;
            width: 100%;
            text-align: left;
        }

        &__choosen {
            cursor: pointer;
            font-size: $font-size-default;
            border-radius: $border-radius-input;
            height: 100%;
            line-height: rem($height-input-md-raw - 2);
            padding: 0 rem(14);
            position: relative;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;

            &--searchable {
                cursor: text;
            }

            &--hidden {
                opacity: 0;
                z-index: -1;
            }

            &--disabled {
                background-color: #eee;
                cursor: default;
                opacity: 1;
            }
        }

        &__search {
            border: 1px solid transparent;
            bottom: 0;
            left: 0;
            position: absolute;
            right: 0;
            top: 0;

            &--hidden {
                opacity: 0;
                z-index: -1;
            }
        }

        &__search-input {
            appearance: none;
            background-color: transparent;
            border: none;
            bottom: 0;
            box-sizing: border-box;
            font-size: $font-size-default;
            left: 0;
            line-height: $height-input-md;
            outline: none;
            padding: 0 rem(14);
            position: absolute;
            top: 0;
            width: 100%;

            &::-ms-clear,
            &::-ms-reveal {
                display:none;
            }
        }

        &__options {
            background-color: #fff;
            border-radius: 4px;
            box-shadow: 0 2px 10px rgba(0, 0, 0, .15);
            left: -1px;
            overflow: auto;
            position: absolute;
            top: $height-input-md;
            width: calc(100% + 2px);
            z-index: 5;
        }

        &__option {
            cursor: pointer;
            font-size: $font-size-default;
            line-height: 130%;
            padding: rem(7) rem(14);

            &:hover, &--highlighted {
                background-color: rgba(0, 0, 0, .05);
            }

            &--selected {
                background-color: rgba(0, 0, 0, .1);
            }

            &:first-of-type {
                margin-top: rem(7);
            }

            &:last-of-type {
                margin-bottom: rem(7);
            }
        }

        // Icons
        &__icon-wrapper {
            @include input-icon-wrapper;

            &--chevron {
                padding: 0 3px;
                pointer-events: none;
                width: rem(6);
            }

            &--search {
                pointer-events: none;
                width: rem(12);
            }
        }

        &__icon {
            @include input-icon;

            transition: transform .3s ease-in-out;

            &--chevron {
                transform: rotate(90deg);
            }

            &--chevron-active {
                transform: rotate(270deg);
            }
        }

        // Label
        &__text {
            @include label;

            &--active {
                @include label-active;
            }

            &--error {
                @include label-error;
            }
        }

        // Errors wrapper
        &__errors-wrapper {
            @include errors-wrapper;
        }
    }
</style>
