<template>
    <div class="home">
        <home-header />
        <home-content />
        <home-footer />
    </div>
</template>

<script>
    import Vue from 'vue'

    import HomeHeader from 'Components/components/HomeHeader.vue'
    import HomeContent from 'Components/components/HomeContent.vue'
    import HomeFooter from 'Components/components/HomeFooter.vue'

    export default {
        name: 'Home',
        components: {
            HomeHeader,
            HomeContent,
            HomeFooter
        }
    }
</script>
<style>
    html, body {
        font-size: 16px;
    }

    .home {
        overflow-x: hidden;
    }
</style>
