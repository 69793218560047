<template>
    <div class="success-modal popup-item">
        <div 
            class="success-modal__title"
            v-text="name"
        />
        <div 
            class="success-modal__about success-modal__about--bold"
            v-text="title"
        />
        <div 
            class="success-modal__about"
            v-text="text"
        />
        <w-button
            class="j-reg_init success-modal__button"
            :wide="false"
        >
            Зарегистрироваться
        </w-button>
    </div>
</template>

<script>
    import WButton from 'Components/js/ui/Button.vue'

    export default {
        name: 'HomeSuccessModal',
        components: {
            WButton
        },
        props: {
            title: {
                type: String,
                default: ''
            },
            text: {
                type: String,
                default: ''
            },
            name: {
                type: String,
                default: ''
            }
        }
    }
</script>

<style lang="scss" scoped>
    .success-modal {
        text-align: left;

        &__title {
            font-weight: 700;
            font-size: 24px;
            line-height: 100%;
            letter-spacing: 0.02em;
            padding-bottom: 14px;
        }

        &__about {
            font-size: 16px;
            line-height: 150%;
            padding-bottom: 28px;

            &--bold {
                font-weight: 700;
                font-size: 16px;
                line-height: 130%;
            }
        }

        &__button {
            display: flex;
            justify-content: center;
        }
    }

    .span--blue {
        font-weight: 700;
        color: #0ca9ef;
    }
</style>
